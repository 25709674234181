import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { useState } from "react";
export const Intro = ({
  label
}) => {
  const [count, setCount] = useState(0);
  return count == 0 ? <p>
      <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
        Hi, I'm Juan
      </Text>
      <br />
      I’m a{" "}
      <Text sx={{
      fontWeight: `bold`,
      color: `primary`
    }} mdxType="Text">
        Software Engineering Manager
      </Text>{" "}
      at Apple based in Cupertino, California. I enjoy building teams and
      software, and spending time helping others grow their careers. Work aside,
      I like to spend time with my wife and puppy, read books, soccer, run &{" "}
      <Text sx={{
      fontWeight: `bold`,
      color: `primary`,
      cursor: `pointer`,
      textDecoration: `underline`,
      textDecoration: `underline`
    }} onClick={() => setCount(count + 1)} mdxType="Text">
        learn languages
      </Text>
      .
    </p> : count == 1 ? <p>
      <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
        Hola, soy Juan
      </Text>
      <br />
      Soy un{" "}
      <Text sx={{
      fontWeight: `bold`,
      color: `primary`
    }} mdxType="Text">
        Gerente de Ingeniería de Software
      </Text>{" "}
      en Apple, ubicado en Cupertino, California. Disfruto de construir equipos
      y desarrollar software, así como pasar tiempo ayudando a otros a crecer
      sus carreras. Aparte del trabajo, me gusta pasar tiempo con mi esposa y
      nuestra cachorra, leer libros, fútbol, correr y{" "}
      <Text sx={{
      fontWeight: `bold`,
      color: `primary`,
      cursor: `pointer`,
      textDecoration: `underline`
    }} onClick={() => setCount(count + 1)} mdxType="Text">
        aprender lenguajes
      </Text>
      .
    </p> : count == 2 ? <p>
      <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
        你好，我是胡安
      </Text>
      <br />
      我是苹果的
      <Text sx={{
      fontWeight: `bold`,
      color: `primary`
    }} mdxType="Text">
        软件工程经理
      </Text> 位于加利福尼亚州库比蒂诺. 我喜欢组建团队和开发软件,并花时间帮助他人发展自己的事业.
      当我不工作时，我喜欢和我的妻子和小狗共度时光，读书，踢足球，跑步和<Text sx={{
      fontWeight: `bold`,
      color: `primary`,
      cursor: `pointer`,
      textDecoration: `underline`
    }} onClick={() => setCount(count + 1)} mdxType="Text">
        学习语言
      </Text>.
    </p> : count == 3 ? <p>
      <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
        Oi, eu sou o Juan
      </Text>
      <br />
      Sou um{" "}
      <Text sx={{
      fontWeight: `bold`,
      color: `primary`
    }} mdxType="Text">
        Gerente de Engenharia de Software
      </Text>{" "}
      da Apple, com sede em Cupertino, Califórnia. Gosto de construir equipes e
      software, e de passar o tempo ajudando outras pessoas a crescerem em suas
      carreiras. Trabalho à parte, gosto de ficar com minha esposa e
      cachorrinho, ler livros, futebol, correr e{" "}
      <Text sx={{
      fontWeight: `bold`,
      color: `primary`,
      cursor: `pointer`,
      textDecoration: `underline`
    }} onClick={() => setCount(count + 1)} mdxType="Text">
        aprender línguas
      </Text>
      .
    </p> : count == 4 ? <p>
      <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
        Salut, je suis Juan
      </Text>
      <br />
      Je suis un{" "}
      <Text sx={{
      fontWeight: `bold`,
      color: `primary`
    }} mdxType="Text">
        Gérant de Ingénierie Logicielle
      </Text>{" "}
      chez Apple basé à Cupertino, en Californie. J'aime créer des équipes et
      des logiciels, et passer du temps à aider les autres à faire progresser
      leur carrière. A part le travail, j'aime passer du temps avec ma femme et
      mon chiot, lire des livres, faire du foot, courir et{" "}
      <Text sx={{
      fontWeight: `bold`,
      color: `primary`,
      cursor: `pointer`,
      textDecoration: `underline`
    }} onClick={() => setCount(count + 1)} mdxType="Text">
        apprendre des langues
      </Text>
      .
    </p> : count == 5 ? <p style={{
    textAlign: `right`
  }}>
      <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
        مرحبًا ، أنا خوان
      </Text>
      <br /> أنا<Text sx={{
      fontWeight: `bold`,
      color: `primary`
    }} mdxType="Text">
        مدير هندسة البرمجيات
      </Text>أنا مقرها في كوبرتينو ، كاليفورنيا. أستمتع بتكوين الفرق والبرامج ، وقضاء
      الوقت في مساعدة الآخرين على تنمية حياتهم المهنية. بعيدًا عن العمل ، أحب قضاء
      الوقت مع زوجتي وجرو ، وقراءة الكتب وكرة القدم والركض و<Text sx={{
      fontWeight: `bold`,
      color: `primary`,
      cursor: `pointer`,
      textDecoration: `underline`
    }} onClick={() => setCount(count + 1)} mdxType="Text">
        تعلم اللغات
      </Text>
    </p> : count == 6 ? <p>
      <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
        привет, я хуан
      </Text>
      <br />Я <Text sx={{
      fontWeight: `bold`,
      color: `primary`
    }} mdxType="Text">
        менеджер по разработке программного обеспечения
      </Text> в Apple из Купертино, Калифорния. Мне нравится создавать команды и
      программное обеспечение, а также проводить время, помогая другим делать карьеру.
      Помимо работы, мне нравится проводить время с женой и щенком, читать книги,
      футбол, бегать и <Text sx={{
      fontWeight: `bold`,
      color: `primary`,
      cursor: `pointer`,
      textDecoration: `underline`
    }} onClick={() => setCount(0)} mdxType="Text">
        изучать языки
      </Text>.
    </p> : <div>hi</div>;
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  Intro,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      